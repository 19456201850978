import { ref } from "vue";
import { useRouter } from "vue-router";
import { showToast } from "@/utils/utils";
export default {
  name: "pinCode",

  setup() {
    const router = useRouter();
    const pin = ref(""); // 存储PIN码

    const confirmPin = ref(""); // 存储确认PIN码

    const otp = ref(""); // 存储 OTP

    const showKeyboard = ref(false); // 控制数字键盘显示与隐藏

    const isSending = ref(false); // 是否正在发送 OTP

    const countdown = ref(0); // 倒计时

    const errors = ref({
      otp: ""
    }); // 错误信息

    const onClickLeft = () => {
      router.back(); // 返回上一页
    };

    const onSubmit = () => {
      if (pin.value.length < 6) {
        showToast("Please enter the 6-digit PIN.");
      } else if (confirmPin.value !== pin.value) {
        showToast("PIN and Confirm PIN do not match.");
      } else if (otp.value.length !== 6) {
        showToast("Please enter the 6-digit OTP.");
      } else {
        // 提交成功后跳转
        router.push("/tool/selecttool/airtelOTP");
      }
    }; // 当密码输入框聚焦时，显示键盘


    const onFocus = () => {
      showKeyboard.value = true;
    }; // 当键盘失去焦点时，隐藏键盘


    const onBlur = () => {
      showKeyboard.value = false;
    };

    const startCountdown = () => {
      countdown.value = 60;
      const interval = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    };

    const sendOtp = () => {
      if (countdown.value > 0 || isSending.value) return;
      isSending.value = true;
      setTimeout(() => {
        isSending.value = false;
        startCountdown();
      }, 2000); // 模拟发送 OTP 的延迟时间
    };

    return {
      pin,
      confirmPin,
      otp,
      showKeyboard,
      isSending,
      countdown,
      errors,
      onClickLeft,
      onFocus,
      onBlur,
      sendOtp,
      onSubmit
    };
  }

};